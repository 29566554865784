
.login-overlay{
  background-image: linear-gradient(to right, #1B2D3E 40%, transparent 60%);
}

.slick-dots li button:before{
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  color: #F5F5F5 !important;
  font-size: 13px !important;
  
}
.slick-dots li.slick-active button:before{
  color: #5BD318 !important;
}
.gift-card{
  background: linear-gradient(129.43deg, #C2FFA1 18.29%, #ECFFE2 72.56%);
}
