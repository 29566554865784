@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 
    
    'Poppins','bone-white';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

colors {
        boneWhite: '#F9F6EE'
      }
input::placeholder {
  @apply text-gray-500; /* Lighter text color for the placeholder */
}